<template>
  <div class="container">
    <Form
      :title="`Unlock Instant Offers for ${companyData.companyName || '...updating'}`"
      :form="form"
      :iloSubTitles="iloSubTitles"
      :tooltipLeft="true"
      :loading="loading"
      :backButtonEnabled="false"
      @submit="submit"
      @keyup.enter="submit"
      submitButtonText="Show me my offers"
      :splitDataObject="false"
    >
      <template v-slot:after-fields>
        <p class="data-consent">
          We here at Swoop want you to be confident in how your data will be used:
          <ul>
            <li>Data you provide will only be shared with lenders for the purposes of finding you a loan.</li>
            <li>Swoop and its providers may need to run a soft credit check on you, but this won't affect your credit score.</li>
            <li>You may receive emails from our providers, outlining your offers.</li>
            <li>Our Swoop experts may get in touch to discuss your options.</li>
          </ul>
        </p>
      </template>
    </Form>
    <div v-if="loading">
      <div class="ui active inverted dimmer page-loader">
        <div class="ui medium text loader">
          <spinner :size="64" :loader="true" color />
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {
  createCompanyOpportunity,
  checkWithProviders,
  getFieldDefinitions,
  updateCompanyOpportunity,
  getRequiredFields,
  createCompanyContact,
  updateCompanyContact,
  createCompanyContactAddress,
  updateCompanyContactAddress
} from '@product/api/aggregator'
import Form from '@product/features/products/components/Form'
import { authGetters } from '@/store/modules/auth/routines'
import { amplitudeEvents } from '@/utils/constants'
import { mapGetters } from 'vuex'
import { companyGetters } from '@/store/modules/company/routines'
import { getCompany } from '@/api/company'
import notifies from '@/mixins/notifies'
import deepLinking from '@/mixins/deepLinking'
import mapsFields from '@/mixins/mapsFields'
import Spinner from '@/components/Spinner'

export default {
  components: { Form, Spinner },
  mixins: [notifies, mapsFields, deepLinking],
  async mounted() {
    this.checkForDeepLinkShift()
    try {
      const result = await this.createOpportunity(this.companyId)
      this.currentOpportunityId = result[0].opportunityId
      await this.setupForm(this.companyId, this.currentOpportunityId)
    } catch (error) {
      this.providerErrors = error
      this.loading = false
    }
    this.loadData(this.companyId)
  },
  data() {
    return {
      form: [],
      currentOpportunityId: null,
      companyOpportunityId: null,
      companyData: {},
      companyOpportunities: [],
      providerErrors: null,
      formSections: [],
      fieldDefinitions: [],
      loading: true,
      locked: true,
      iloSubTitles: [
        'The final few details below will help us find you an offer of up to £1,000,000 for your business in minutes.',
        "We will use these details to apply on your behalf to our lending partners, and their best offers will appear on your 'Matches' page.",
        "If you like the look of one of your 'Quick Offers', you can accept it and progress the application through the lender's website."
      ],
      vatField: null,
      isAllProvidersResponded: false
    }
  },
  computed: {
    ...mapGetters({
      companyId: companyGetters.COMPANY_ID,
      user: authGetters.USER
    })
  },
  watch: {
    providerErrors(errors) {
      if (errors) {
        this.$ma.trackEvent(amplitudeEvents.AggregatorApi.INSTANT_OFFER_ERROR)
        this.notifyError()
      }
    }
  },
  methods: {
    loadData(companyId) {
      this.getCompanyData(companyId)
      this.$ma.trackEvent(amplitudeEvents.AggregatorApi.UNLOCK)
    },
    async createOpportunity(companyId) {
      const {
        data: { opportunityId }
      } = await createCompanyOpportunity(companyId)
      await checkWithProviders(companyId, opportunityId)
      return [{ opportunityId }]
    },
    getCompanyData(companyId) {
      getCompany(companyId)
        .then(res => {
          this.companyData = res.data
        })
        .catch(error => {
          this.companyData = {}
          console.error(error)
        })
    },
    trackIntersection() {
      if (!this.loanProductsLocked) {
        this.$ma.trackEvent({ eventType: amplitudeEvents.Matches.INSTANT_OFFER_CTA })
      }
    },
    async setupFieldDefinitions() {
      if (this.fieldDefinitions.length) return
      const {
        data: { fieldDefinitions, formSections }
      } = await getFieldDefinitions()

      this.fieldDefinitions = fieldDefinitions
      this.formSections = formSections
    },
    async setupRequiredFields(companyId, opportunityId) {
      let limit = 60

      while (limit > 0 && !this.isAllProvidersResponded) {
        const {
          data: { opportunityRequiredFields, allProvidersResponded }
        } = await getRequiredFields(companyId, opportunityId)
        if (allProvidersResponded) {
          this.mapProviderResponsesToFields(opportunityRequiredFields)
          setTimeout(() => {
            this.loading = false
          }, 500)
          this.isAllProvidersResponded = allProvidersResponded
        } else {
          await new Promise(resolve => setTimeout(resolve, 1000))
        }
        limit--
      }
    },
    async setupForm(companyId, opportunityId) {
      await this.setupFieldDefinitions()
      await this.setupRequiredFields(companyId, opportunityId)
    },
    mapFieldRules({ validationRules }) {
      if (!Array.isArray(validationRules) || !validationRules.length) return
      return validationRules
        .map(rule => {
          if (rule.value) {
            return `${rule.name}:${rule.value}`
          }
          return rule.name
        })
        .join('|')
    },
    mapProviderResponsesToFields(opportunityRequiredFields) {
      let missingFields = []
      if (opportunityRequiredFields && opportunityRequiredFields.length) {
        opportunityRequiredFields.forEach(missingField => {
          if (missingFields.find(field => field.name === missingField)) {
            return
          }

          const foundField = this.fieldDefinitions.find(field => field.fieldDefinitionId === missingField.fieldDefinitionId)
          const foundSection = this.formSections.find(section => section.formSectionId === foundField.formSectionId)

          if (foundField.dataType === 'String') {
            foundField.type = 'input'
            delete foundField.dataType
          }

          if (Array.isArray(foundField.options)) {
            foundField.type = 'select'
          }

          if (!foundField || foundField.readOnly) {
            return false
          }

          if (foundField.dataType === 'Date') {
            foundField.type = 'date'
            foundField.dataType = 'day'
            foundField.returnType = 'UTC'
            foundField.mode = 'aggressive'
          }

          if (foundField.dataType === 'Bool') {
            foundField.type = 'radio'
            foundField.options = [
              {
                label: 'Yes',
                value: true
              },
              {
                label: 'No',
                value: false
              }
            ]
          }

          const IsVatRegisteredOverThreeMonths = opportunityRequiredFields.find(field => field.fieldName === 'Opportunity.IsVatRegisteredOverThreeMonths')

          if (foundField.name === 'Opportunity.VatNumber' && IsVatRegisteredOverThreeMonths) {
            foundField.validationRules = [{ name: 'required' }]
            foundField.dependsOn = [
              {
                field: 'Opportunity.IsVatRegisteredOverThreeMonths',
                value: true
              }
            ]
          }

          if (foundField.dataType === 'Decimal') {
            foundField.type = 'input'
            foundField.dataType = 'number'
            foundField.icon = 'currency'
            foundField.iconPosition = 'left'
          }

          if (foundField.dataType === 'Integer') {
            foundField.type = 'input'
            foundField.dataType = 'number'
          }

          if (foundField.name === 'Applicant.IsDirector') {
            foundField.tooltip =
              'In the case of a limited company the applicant needs to be a director with a beneficial ownership of greater than 25%. It is important that this individual is of good credit standing.'
          }

          foundField.rules = this.mapFieldRules(foundField)

          missingFields.push({
            ...missingField,
            label: foundField.displayName,
            type: 'input',
            value: '',
            ...foundField,
            section: foundSection
          })
        })

        missingFields = missingFields.filter(field => field).sort((a, b) => a.section.displayOrder - b.section.displayOrder || a.displayOrder - b.displayOrder)

        this.form = missingFields
        this.prefillFormData()
      }
    },
    prefillFormData() {
      const fields = [
        {
          name: 'Contact.FirstName',
          value: this.user.firstName
        },
        {
          name: 'Contact.LastName',
          value: this.user.lastName
        },
        {
          name: 'Contact.Title',
          value: this.user.title || ''
        },
        {
          name: 'Contact.EmailAddress',
          value: this.user.email
        },
        {
          name: 'Contact.PhoneNumber',
          value: this.user.contactNumber
        },
        {
          name: 'Contact.DateOfBirth',
          value: this.user.doB || ''
        }
      ]
      fields.forEach(field => this.attachToFieldValue(field.name, field.value))
    },
    async submit(data) {
      this.loading = true
      try {
        const fields = Object.keys(data)
          .filter(fieldName => data[fieldName] !== null)
          .map(fieldName => {
            return {
              name: fieldName,
              value: data[fieldName].toString().trim()
            }
          })
          .filter(field => field.value)
        const contactFields = fields.filter(field => field.name.includes('Contact.'))
        const contactAddressFields = fields.filter(field => field.name.includes('ContactAddress.'))

        let applicationContact = null

        if (contactFields.length) {
          applicationContact = await createCompanyContact(this.companyId)
          await updateCompanyContact(this.companyId, applicationContact.data.contactId, contactFields)
          fields.push({
            name: 'Opportunity.ApplicantId',
            value: applicationContact.data.contactId
          })
        }
        if (contactAddressFields.length && applicationContact) {
          const applicationContactAddress = await createCompanyContactAddress(this.companyId, applicationContact.data.contactId)
          await updateCompanyContactAddress(this.companyId, applicationContact.data.contactId, applicationContactAddress.data.contactAddressId, contactAddressFields)
        }
        await updateCompanyOpportunity(
          this.companyId,
          this.currentOpportunityId,
          fields.filter(field => !field.name.includes('Contact') && field.name !== 'isLoanApplicant')
        )
        await checkWithProviders(this.companyId, this.currentOpportunityId)

        const res = await getRequiredFields(this.companyId, this.currentOpportunityId)
        const leftRequiredFields = res.data.opportunityRequiredFields

        if (leftRequiredFields.length && !this.isAllProvidersResponded) {
          const requiredFields = await getRequiredFields(this.companyId, this.currentOpportunityId)

          const isCurrentBankName = Boolean(requiredFields.data.opportunityRequiredFields.find(field => field.fieldName === 'Company.CurrentBankName'))
          if (isCurrentBankName) {
            this.$router.push({ name: 'goals' }) // If there is missing field user should fill the information to able to get the results
          }

          await new Promise(resolve => setTimeout(resolve, 1000))
          await this.setupRequiredFields(this.companyId, this.opportunityId)
        } else {
          this.submittedFields = data
          window.localStorage.setItem('submittedFields', 'submittedFields')
          this.$ma.trackEvent({
            eventType: amplitudeEvents.AggregatorApi.INSTANT_OFFER_RECEIVED,
            eventProperties: {
              ...this.submittedFields
            }
          })
          this.$ma.trackEvent(amplitudeEvents.AggregatorApi.SUBMIT_FORM)

          this.$router.push({ name: 'loan-matches' })
        }
      } catch (error) {
        this.loading = false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables.scss';

.container {
  margin-top: 22px;
  @media only screen and (max-width: 1550px) {
    padding: 0 24px;
  }
}
</style>
